import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _362d8aec = () => interopDefault(import('..\\pages\\accountInfo.vue' /* webpackChunkName: "pages/accountInfo" */))
const _37d9faa8 = () => interopDefault(import('..\\pages\\accountInfo\\collection.vue' /* webpackChunkName: "pages/accountInfo/collection" */))
const _67c39334 = () => interopDefault(import('..\\pages\\accountInfo\\data.vue' /* webpackChunkName: "pages/accountInfo/data" */))
const _69abc883 = () => interopDefault(import('..\\pages\\accountInfo\\overview.vue' /* webpackChunkName: "pages/accountInfo/overview" */))
const _b85ea04a = () => interopDefault(import('..\\pages\\accountInfo\\record.vue' /* webpackChunkName: "pages/accountInfo/record" */))
const _5002ab02 = () => interopDefault(import('..\\pages\\aimbot\\index.vue' /* webpackChunkName: "pages/aimbot/index" */))
const _43e71f88 = () => interopDefault(import('..\\pages\\b5plusprotocol\\index.vue' /* webpackChunkName: "pages/b5plusprotocol/index" */))
const _4021e043 = () => interopDefault(import('..\\pages\\banned\\index.vue' /* webpackChunkName: "pages/banned/index" */))
const _7fbac31a = () => interopDefault(import('..\\pages\\contact\\index.vue' /* webpackChunkName: "pages/contact/index" */))
const _0b6a7ed9 = () => interopDefault(import('..\\pages\\download\\index.vue' /* webpackChunkName: "pages/download/index" */))
const _56b1823e = () => interopDefault(import('..\\pages\\Exchange\\index.vue' /* webpackChunkName: "pages/Exchange/index" */))
const _7bd15d77 = () => interopDefault(import('..\\pages\\gameTool\\index.vue' /* webpackChunkName: "pages/gameTool/index" */))
const _161d0a5e = () => interopDefault(import('..\\pages\\Management\\index.vue' /* webpackChunkName: "pages/Management/index" */))
const _107072b5 = () => interopDefault(import('..\\pages\\myTournaments\\index.vue' /* webpackChunkName: "pages/myTournaments/index" */))
const _569979a4 = () => interopDefault(import('..\\pages\\news\\index.vue' /* webpackChunkName: "pages/news/index" */))
const _fdc5626e = () => interopDefault(import('..\\pages\\protocol\\index.vue' /* webpackChunkName: "pages/protocol/index" */))
const _14816035 = () => interopDefault(import('..\\pages\\rank\\index.vue' /* webpackChunkName: "pages/rank/index" */))
const _20752b8e = () => interopDefault(import('..\\pages\\tournaments.vue' /* webpackChunkName: "pages/tournaments" */))
const _033ebe5e = () => interopDefault(import('..\\pages\\tournaments\\_id.vue' /* webpackChunkName: "pages/tournaments/_id" */))
const _991e2f8a = () => interopDefault(import('..\\pages\\tournaments\\_id\\data.vue' /* webpackChunkName: "pages/tournaments/_id/data" */))
const _2fbc0596 = () => interopDefault(import('..\\pages\\tournaments\\_id\\final.vue' /* webpackChunkName: "pages/tournaments/_id/final" */))
const _75ed7bec = () => interopDefault(import('..\\pages\\tournaments\\_id\\overview.vue' /* webpackChunkName: "pages/tournaments/_id/overview" */))
const _4071fabd = () => interopDefault(import('..\\pages\\tournaments\\_id\\stage.vue' /* webpackChunkName: "pages/tournaments/_id/stage" */))
const _354cad24 = () => interopDefault(import('..\\pages\\tournaments\\_id\\team.vue' /* webpackChunkName: "pages/tournaments/_id/team" */))
const _bc45fff0 = () => interopDefault(import('..\\pages\\verify\\index.vue' /* webpackChunkName: "pages/verify/index" */))
const _5baaf343 = () => interopDefault(import('..\\pages\\Help\\Help.vue' /* webpackChunkName: "pages/Help/Help" */))
const _342f0c91 = () => interopDefault(import('..\\pages\\protocol\\Protocol.vue' /* webpackChunkName: "pages/protocol/Protocol" */))
const _ff935cc2 = () => interopDefault(import('..\\pages\\gameTool\\_id.vue' /* webpackChunkName: "pages/gameTool/_id" */))
const _3114c346 = () => interopDefault(import('..\\pages\\myTournaments\\_id.vue' /* webpackChunkName: "pages/myTournaments/_id" */))
const _10f43916 = () => interopDefault(import('..\\pages\\news\\_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _6f40a0a1 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _fe9ac5a4 = () => interopDefault(import('..\\pages\\_.vue' /* webpackChunkName: "pages/_" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accountInfo",
    component: _362d8aec,
    name: "accountInfo",
    children: [{
      path: "collection",
      component: _37d9faa8,
      name: "accountInfo-collection"
    }, {
      path: "data",
      component: _67c39334,
      name: "accountInfo-data"
    }, {
      path: "overview",
      component: _69abc883,
      name: "accountInfo-overview"
    }, {
      path: "record",
      component: _b85ea04a,
      name: "accountInfo-record"
    }]
  }, {
    path: "/aimbot",
    component: _5002ab02,
    name: "aimbot"
  }, {
    path: "/b5plusprotocol",
    component: _43e71f88,
    name: "b5plusprotocol"
  }, {
    path: "/banned",
    component: _4021e043,
    name: "banned"
  }, {
    path: "/contact",
    component: _7fbac31a,
    name: "contact"
  }, {
    path: "/download",
    component: _0b6a7ed9,
    name: "download"
  }, {
    path: "/Exchange",
    component: _56b1823e,
    name: "Exchange"
  }, {
    path: "/gameTool",
    component: _7bd15d77,
    name: "gameTool"
  }, {
    path: "/Management",
    component: _161d0a5e,
    name: "Management"
  }, {
    path: "/myTournaments",
    component: _107072b5,
    name: "myTournaments"
  }, {
    path: "/news",
    component: _569979a4,
    name: "news"
  }, {
    path: "/protocol",
    component: _fdc5626e,
    name: "protocol"
  }, {
    path: "/rank",
    component: _14816035,
    name: "rank"
  }, {
    path: "/tournaments",
    component: _20752b8e,
    name: "tournaments",
    children: [{
      path: ":id?",
      component: _033ebe5e,
      name: "tournaments-id",
      children: [{
        path: "data",
        component: _991e2f8a,
        name: "tournaments-id-data"
      }, {
        path: "final",
        component: _2fbc0596,
        name: "tournaments-id-final"
      }, {
        path: "overview",
        component: _75ed7bec,
        name: "tournaments-id-overview"
      }, {
        path: "stage",
        component: _4071fabd,
        name: "tournaments-id-stage"
      }, {
        path: "team",
        component: _354cad24,
        name: "tournaments-id-team"
      }]
    }]
  }, {
    path: "/verify",
    component: _bc45fff0,
    name: "verify"
  }, {
    path: "/Help/Help",
    component: _5baaf343,
    name: "Help-Help"
  }, {
    path: "/protocol/Protocol",
    component: _342f0c91,
    name: "protocol-Protocol"
  }, {
    path: "/gameTool/:id",
    component: _ff935cc2,
    name: "gameTool-id"
  }, {
    path: "/myTournaments/:id",
    component: _3114c346,
    name: "myTournaments-id"
  }, {
    path: "/news/:id",
    component: _10f43916,
    name: "news-id"
  }, {
    path: "/",
    component: _6f40a0a1,
    name: "index"
  }, {
    path: "/*",
    component: _fe9ac5a4,
    name: "all"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
